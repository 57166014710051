import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "List",
  "type": "Content"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`List`}</strong>{` component renders an `}<inlineCode parentName="p">{`ol`}</inlineCode>{` or a `}<inlineCode parentName="p">{`ul`}</inlineCode>{` element used for creating an ordered or
unordered lists. The `}<strong parentName="p">{`List`}</strong>{` component should be used with `}<strong parentName="p">{`ListItem`}</strong>{` in order for
the styles to work.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false",
        "live": "true",
        "editor": "false"
      }}>{`<List>
  <ListItem>One</ListItem>
  <ListItem>Two</ListItem>
  <ListItem>Three</ListItem>
</List>
`}</code></pre>
    <h2>{`Props of `}<inlineCode parentName="h2">{`List`}</inlineCode></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ordered`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the list should be ordered or not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The list items of this list`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props of `}<inlineCode parentName="h2">{`ListItem`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`ListItem`}</strong>{` renders it's content inside a `}<inlineCode parentName="p">{`span`}</inlineCode>{` element`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The item's text`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Variations`}</h2>
    <h3>{`Ordered list`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<List ordered>
  <ListItem>One</ListItem>
  <ListItem>Two</ListItem>
  <ListItem>Three</ListItem>
</List>
`}</code></pre>
    <h3>{`Unordered list`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<List>
  <ListItem>One</ListItem>
  <ListItem>Two</ListItem>
  <ListItem>Three</ListItem>
</List>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      